<template>
  <div class="rounded-lg shadow-lg">
    <form-component :customer="customer" mode="create" />
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent.vue";
export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      customer: {
        customer: {
          tfn: "",
          abn: "",
          acn: "",
          company_id: null,
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          state: "",
          industry: "",
          avatar: "",
          is_roster: 1,
          contacts: [
            {
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              designation_id: "",
              designation: "",
            },
          ],
          financial: [
            {
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              designation_id: "",
              designation: "",
              type: "financial",
            },
          ],
        },
      },
    };
  },
  methods: {},
};
</script>

<style scoped>

</style>
